import React, { useEffect } from "react";
import MarketSelector from "../components/global/MarketSelector";
import GlobalLayout from "../components/global/GlobalLayout";
import { navigate } from "gatsby";

const Home = () => {
  useEffect(() => {
    const redirect = async () => {
      const locale = localStorage.getItem("locale");
      if (locale) {
        await navigate(`/${locale}`);
      }
    };
    redirect();
  }, []);

  return (
    <GlobalLayout>
      <MarketSelector />
    </GlobalLayout>
  );
};

export default Home;
