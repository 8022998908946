import React, { useState, useEffect, useCallback } from 'react'
import logo from "../../assets/images/logo.svg"
import Map from "../../components/global/about/Map"
import MapNoLinks from "../../components/global/about/MapNoLinks"
import {Link} from "../../utils/url-optimizer";


const MartketSelector = () => {
    const countriesRefs = {}
    const [overedCountry, setOveredCountry] = useState()

    useEffect(() => {
        Object.entries(countriesRefs).forEach(([key, value]) => {
            value.addEventListener('mouseover', () => countryOver(key))
        })
    }, [countriesRefs])

    const countryOver = useCallback((cntry) => {
        setOveredCountry(cntry)
    }, [])

    return (
        <div className=''>
            <div className="bg-black text-white overflow-auto">
                <div className="container-fluid">
                    <div className='container vh-100'>
                        <div className='market-selector'>
                            <div className=''>
                                <div className=''>
                                    <img src={logo} className='map-photo pe-3' alt="logo" />
                                    <span className="text-nowrap">by region</span>
                                 </div>
                                 <div className=''>
                                    <h1 className='market-selector-heading '>Please select country to continue.</h1>
                                </div>
                                <div className='pt-6 d-none d-lg-block'>
                                    <Map onCountryOver={(country) => setOveredCountry(country) } selectedCountry={overedCountry} />
                                </div>
                            </div>
                            
                            <div className={ `market-selector-widget overlapmap` }>
                                <h5 ref={ref => countriesRefs['au'] = ref}
                                    className={ ` pt-4 mobile-market-selector-country${overedCountry === 'au' ? '-selected' : ''}` }>
                                    <Link to="/au/" className="" rel="noreferrer">
                                        Australia
                                    </Link>
                                </h5>
                                <h5 ref={ref => countriesRefs['ch'] = ref}
                                    className={ ` pt-4 mobile-market-selector-country${overedCountry === 'ch' ? '-selected' : ''}` }>
                                    <Link to="/ch/de/" className="" rel="noreferrer">
                                        Switzerland
                                    </Link>
                                </h5>
                                <h5 ref={ref => countriesRefs['nl'] = ref}
                                    className={ ` pt-4 mobile-market-selector-country${overedCountry === 'nl' ? '-selected' : ''}` }>
                                    <Link to="/nl/" className="" rel="noreferrer">
                                        The Netherlands
                                    </Link>
                                </h5>
                                <h5 ref={ref => countriesRefs['uk'] = ref} 
                                    className={ ` pt-4 mobile-market-selector-country${overedCountry === 'uk' ? '-selected' : ''}` }>
                                    <Link to="/uk/" className="" rel="noreferrer">
                                        United Kingdom
                                    </Link>
                                </h5>
                            </div>
                            <div className='global-map-tablet'>
                                <MapNoLinks />
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default MartketSelector;