import React, { useEffect, useState } from "react";
import { RecoilRoot } from "recoil";
import GoTop from "../common/GoTop";
import SEO from "./SEO";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import { setGtmEnabledOn } from "../../utils/google-tag-manager";
import {getConsentStatus} from "../../utils/cookie-manager";
import CookieBanner from "./cookie/cookieBanner";
import CookieConsentPopup from "./cookie/cookieConsentPopup";

const Layout = ({ children }) => {
  const location = useLocation();

  const [showConsent, setShowConsent] = useState(false);
  const [showConsentAsModal, setShowConsentAsModal] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const data = useStaticQuery(query);
  const [isInvestorPage, setInvestorPage] = useState(false);

  const cookieCountrySettings = data?.allMarkdownRemark?.edges.map(edge => ({
    ...edge?.node?.frontmatter,
  }));
  const consent = data.markdownRemark?.frontmatter.cookies.englishConsent;
  const consentMessages = data.markdownRemark?.frontmatter.cookies;
  const footerData = data?.allFooters?.nodes
      ?.map(node => node.frontmatter)
      .find(x => x.locale === 'global-en');

  const params = new URLSearchParams(location.search);
  const utmSource = params.get("utm_source") != null;

  const siteURL = data?.site?.siteMetadata?.siteUrl;

  useEffect(() => {
    const fetchIpInfo = async () => {
      const data = await fetch(
          "https://pro.ip-api.com/json/?fields=status,message,continentCode,countryCode&key=jPFg5625GauntD6",
          {
            method: "GET",
          }
      );
      return data;
    }

    fetchIpInfo().then(async (response) => {
      if (response.status === 200) {
        const { status, message, continentCode, countryCode } =
            await response.json();
        setCountryCode(countryCode);
        if (status === "success") {
          const isEU = continentCode === "EU";
          const relevantCookieSettings = cookieCountrySettings.find(
              cookieSettings =>
                  cookieSettings.country_code === countryCode.toUpperCase()
          );
          const cmsSettingExists = !!relevantCookieSettings;

          if (cmsSettingExists) {
            const forceBannerModal = relevantCookieSettings.force_cookie_banner;
            const forceUtmBannerModal = relevantCookieSettings.force_utm_cookie_banner;
            const forceUtmActive = forceUtmBannerModal && utmSource;
            const hideBanner = relevantCookieSettings.hide_cookie_banner && !forceBannerModal && !forceUtmActive;
            setShowConsentAsModal(forceBannerModal || forceUtmActive);
            setShowConsent(!hideBanner);

            if (hideBanner) {
              //CMS override to hide banner also sets GA cookies ON
              setGtmEnabledOn(location, true);
            }
          } else {
            //Reasonable defaults if no CMS settings exist
            setShowConsent(isEU);
            // For non-EU users we just set cookies as if they are agree with it
            if (!isEU) {
              setGtmEnabledOn(location, true);
            }
          }
        } else {
          // For unrecognized users we show consent banner by default
          setShowConsent(true);
        }
      } else {
        // If API not working we show consent banner by default
        setShowConsent(true);
      }
    }).catch(() => {
      setShowConsent(true);
    })
  }, [setShowConsent, countryCode]);

  //need to either create a global or point to ch/en
  const cookiePolicyURL = footerData ? footerData.privacyPolicyURL : 'https://tp24group.com/ch/en/privacy';

  let cookieConsentPopUp = <></>;
  if(!getConsentStatus()) {
    if(showConsent) {
      if(showConsentAsModal) {
        cookieConsentPopUp = <CookieConsentPopup cookiePolicyURL={cookiePolicyURL} cookieSettings={consentMessages} language={"en"} location={location} onClickAccept={() => {
          setShowConsent(false);
        }}/>
      } else {
        cookieConsentPopUp = <CookieBanner bannerConsentMessage={consent} location={location}/>
      }
    }
  }

  return (
    <RecoilRoot>
      <SEO siteURL={siteURL}/>
      {children}
      <GoTop scrollStepInPx="100" delayInMs="10.50" />
      {cookieConsentPopUp}
    </RecoilRoot>
  );
};

const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(frontmatter: { cookies: { settings: { eq: true } } }) {
      frontmatter {
        cookies {
          englishConsent
          cookiePolicyTitle
          enCookiePolicyP1
          enCookiePolicyP2
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { page: { eq: "cookie_country" } } }
    ) {
      edges {
        node {
          frontmatter {
            country_code
            hide_cookie_banner
            force_cookie_banner
            force_utm_cookie_banner
          }
        }
      }
    }
  }
`;

export default Layout;
